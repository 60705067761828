<template>
  <div class="login-container">
    <Card class="login-card" style="min-height:400px;">
      <div class="card-title" slot="title">家易量后台登录</div>
      <div class="login-form">
        <Form ref="loginForm" :model="loginModel" :rules="loginRules" label-position="top">
          <FormItem prop="user" label="手机号" >
            <i-input
              type="text"
              v-model="loginModel.user"
              placeholder="请输入手机号"
            >
              <Icon type="ios-person-outline" slot="prepend" size="18"></Icon>
            </i-input>
          </FormItem>
          <FormItem prop="password" label="密码" >
            <i-input
              type="password"
              v-model="loginModel.password"
              placeholder="请输入密码"
            >
              <Icon type="ios-lock-outline" slot="prepend" size="18"></Icon>
            </i-input>
          </FormItem>
          <FormItem style="margin-top: 30px;">
            <Button long type="primary" @click="handleSubmit('loginForm')"
              >登录</Button
            >
          </FormItem>
        </Form>
      </div>
    </Card>
  </div>
</template>

<script>
import api from '@/api';
import Store from '@/store';

export default {
  data() {
    return {
      loginModel: {
        user: "", 
        password: "",
      },
      loginRules: {
        user: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码.",
            trigger: "blur",
          },
          // {
          //   type: "string",
          //   min: 6,
          //   max: 12,
          //   message: "密码长度需在6～12位之间",
          //   trigger: "blur",
          // },
        ],
      },
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          api.login(this.loginModel.user,this.loginModel.password).then(res=>{
            this.$log.debug(res);
            this.$Message.success(res.message);
            res.data.userName = this.loginModel.user
            this.$log.debug(res.data);
            Store.setUserLogin(res.data);
            var path = this.$route.query.redirect;
            if(!path){
              path = '/backend';
            }
            this.$router.replace({path: path});
          }).catch(err=>{
            this.$log.debug(err);
            this.$Message.error('登录失败: '+err.message);
          })
        } 
      });
    },
  },
};
</script>

<style lang="less" scoped>

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  // background-image: url(../assets/login_bg.jpeg);
  // background-size: 100% 100%;

  .login-card {
    width: 100%;
    max-width: 500px;

    .card-title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding: 12px 0;
    }
  }
}
</style>